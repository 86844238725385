<template>
  <v-card elevation="0">
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="$vuetify.breakpoint.mobile">
          <v-text-field
            v-model="filters.bookTitle"
            :label="$t('page.library.fields.search.title')"
            :hint="$t('page.library.fields.search.hint')"
            persistent-hint
            persistent-placeholder
            clearable
            @keydown.enter="loadBookList"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-6">
          <v-data-table
            :headers="headers"
            :items="books"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, -1],
            }"
            :items-per-page="-1"
            @click:row="navigateTo"
            @contextmenu:row="showContextMenu"
          >
            <template v-slot:top v-if="!$vuetify.breakpoint.mobile">
              <v-toolbar flat>
                <v-text-field
                  v-model="filters.bookTitle"
                  :label="$t('page.library.fields.search.title')"
                  :hint="$t('page.library.fields.search.hint')"
                  persistent-hint
                  persistent-placeholder
                  clearable
                  @keydown.enter="loadBookList"
                >
                  <template v-slot:append-outer>
                    <!-- <v-badge
                      :content="nbFilters"
                      :value="nbFilters"
                      color="red"
                      overlap
                    > -->
                    <v-btn icon @click="showFilters = !showFilters">
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                    <!-- </v-badge> -->
                  </template>
                </v-text-field>
                <v-btn text @click="loadBookList">{{
                  $t("app.actions.apply")
                }}</v-btn>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn @click="addBook" depressed>{{
                  $t("page.library.actions.add")
                }}</v-btn>

                <template v-slot:extension v-if="showFilters">
                  <v-row class="mt-4">
                    <v-col cols="4">
                      <v-select
                        v-model="filters.keywords"
                        :label="
                          $t('component.bookSearchDialog.fields.keywords.title')
                        "
                        :items="keywords"
                        dense
                        outlined
                        persistent-placeholder
                        chips
                        deletable-chips
                        small-chips
                        multiple
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                </template>
              </v-toolbar>
            </template>
            <template v-slot:item.title="{ item }">
              <h4 class="my-0 py-0">
                {{ item.title }}
              </h4>
              <v-chip
                v-for="theme in item.themes"
                :key="theme"
                small
                class="mr-1"
              >
                {{ theme }}
              </v-chip>
              <v-chip
                v-for="keyword in item.keywords"
                :key="keyword"
                small
                class="mr-1"
              >
                {{ keyword }}
              </v-chip>
            </template>
            <template v-slot:item.isActive="{ item }">
              <!-- <v-btn tile icon @click="deactivateUser(item)"> -->
              <v-icon dense :color="item.isActive ? 'green' : 'red'">{{
                item.isActive ? "mdi-check" : "mdi-cancel"
              }}</v-icon>
              <!-- </v-btn> -->
            </template>
          </v-data-table>
          <context-menu
            v-model="contextMenu.showMenu"
            :items="contextMenuItems"
            :x="contextMenu.x"
            :y="contextMenu.y"
          ></context-menu>
          <!-- <library-display :books="books"></library-display> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
//import BookCard from "../components/Library/BookCard.vue";
// import LibraryDisplay from "../components/Library/LibraryDisplay.vue";
import * as Routes from "../router/route-names";
import roleMixin from "../mixins/role";
import roles from "../utils/roles";
import ContextMenu from "../components/Shared/ContextMenu.vue";
import { bus } from "../main";
import cleanObjectFn from "../utils/clean-object";
export default {
  //components: { BookCard },
  components: { ContextMenu },
  mixins: [roleMixin],
  data() {
    return {
      showFilters: false,
      pagination: {},
      queryTimeout: null,
      filters: {},
      contextMenu: {
        showMenu: false,
        x: 0,
        y: 0,
        selectedItem: null,
      },
    };
  },
  computed: {
    books() {
      var books = this.$store.getters["library/books"];
      return books;
    },
    contextMenuItems() {
      var menu = [];
      var item = this.contextMenu.selectedItem;
      if (this.isUserSudo && item != null) {
        if (process.env.NODE_ENV != "production")
          menu.push({
            title: "Log",
            action: () => this.log(item),
          });
        menu.push({
          title: item.isActive
            ? this.$t("component.bookGridDisplay.deactivate")
            : this.$t("component.bookGridDisplay.activate"),
          action: () => alert("set book status"),
        });
      }
      return menu;
    },

    headers() {
      var h = [
        { text: this.$t("component.bookGridDisplay.title"), value: "title" },
      ];

      if (this.isInRole(roles.Sudo, roles.Moderator)) {
        h.push({
          text: this.$t("component.bookGridDisplay.headers.isActive"),
          value: "isActive",
        });
      }

      return h;
    },
    keywords() {
      return this.$store.getters["utils/keywords"]
        .filter((x) => x.bookCount > 0)
        .map((x) => x.name);
    },
    routeQuery() {
      return this.$route.query;
    },
  },
  watch: {
    // filters: {
    //   deep: true,
    //   handler(val) {
    //     if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
    //     this.queryTimeout = setTimeout(() => {
    //       console.log("Update query");
    //       let filters = cleanObjectFn({ ...this.routeQuery, ...val });
    //       this.$nextTick(() => {
    //         this.$router.replace({ query: { ...filters } }).catch((e) => {});
    //       });
    //     }, 300);
    //   },
    // },
    // routeQuery: {
    //   deep: true,
    //   handler(val) {
    //     let query = { ...this.pagination, ...val };
    //     this.loadBookList(query);
    //   },
    // },
  },
  methods: {
    addBook() {
      this.$store.dispatch("notification/showBookForm", {});
    },
    loadBookList() {
      this.$nextTick(() => {
        var q = { ...this.filters };
        this.$router.replace({ query: { ...q } }).catch((e) => {});
        this.$store.dispatch("library/loadBooks", { ...q });
      });
    },
    log(item) {
      console.log(item);
    },
    navigateTo($event, row) {
      this.$router.push({
        name: Routes.BOOK,
        params: { bookId: row.item.publicId },
      });
    },

    showContextMenu(e, item) {
      e.preventDefault();
      this.contextMenu.showMenu = false;
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;
      this.contextMenu.selectedItem = item.item;

      this.$nextTick(() => {
        this.contextMenu.showMenu = true;
      });
    },
  },
  created() {
    this.$store.dispatch("utils/loadKeywords", { withBookCount: true });
    // this.query = this.$route.query.query;
    this.filters = this.routeQuery;
    this.loadBookList(this.filters);
    // bus.$on("bookSearchUpdated", (data) => {
    //   this.$nextTick(() => {
    //     let query = { ...this.routeQuery, ...data };
    //     this.$router.push({ query }).catch((x) => {});
    //   });
    // });
    // this.$store.dispatch("library/loadBooks", {});
  },
};
</script>